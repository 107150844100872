<template>
    <div v-for="(item, index) in dc.content.session" :key="item.index" class="pt-28 lg:pt-16">
        <div :id="item.id">
            <div class="flex flex-col justify-center space-x-2 space-y-6 mx-auto overflow-hidden w-11/12">
                <div>
                    <h2 class="text-base lg:text-6xl lg:mt-16 font-bold text-shadow" v-if="index === 0">
                        {{ item.title }}
                    </h2>
                    <h2 class="text-base lg:text-6xl font-bold text-shadow" v-else-if="index === 3">
                        {{ item.title }}
                    </h2>
                    <h2 class="text-base lg:text-6xl font-bold text-shadow lg:mt-8" v-else>
                        {{ item.title }}
                    </h2>
                </div>
                <div class="flex">
                    <div class="lg:w-6/12">
                        <div class="space-y-36 w-fit">
                            <div class="space-y-4 lg:space-y-4 lg:pt-36">
                                <p class="text-xs lg:text-base text-slate-600 w-11/12 lg:w-10/12">
                                    {{ item.label }}
                                </p>
                                <div
                                    class="flex flex-col lg:flex-row justify-center lg:justify-start space-y-4 lg:space-y-0 lg:space-x-2 items-center">
                                    <a href="https://wa.me/5546991105106" target="_blank"
                                        class="shadow-lg rounded-md bg-blue-600 p-2 text-white font-bold flex justify-around w-24 items-center text-xs hover:scale-105"
                                        title="Link do whatsapp Bili Informática">
                                        <img src="../assets/icons/whatsapp_icon.png" width="18" height="18" alt="whatsapp"/>
                                        Whatsapp
                                    </a>
                                    <a class="rounded-md p-2 text-black font-normal flex justify-around border border-sky-400 w-24 items-center text-xs hover:scale-105"
                                        href="#services" title="Link para mais informações" v-if="item.id === 'home'">Saiba Mais</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hidden lg:flex lg:-ml-12">
                        <div class="w-full text-center flex justify-center items-center">
                            <img src="../assets/display/imagem_home.png" width="582" height="482" alt="Bili Informática"
                                v-if="index === 0">
                            <img src="../assets/display/processo_manutencao.png" width="582" height="482"
                                alt="Bili Informática" v-if="index === 1">
                            <img src="../assets/display/notebook.png" width="582" height="482" alt="Bili Informática"
                                v-if="index === 2">
                            <img src="../assets/display/smartphone.png" width="582" height="482" alt="Bili Informática"
                                v-if="index === 3">
                            <img src="../assets/display/console.png" width="582" height="482" alt="Bili Informática"
                                v-if="index === 4">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Services -->
        <div id="services" class="flex flex-col lg:flex-row justify-center items-center w-11/12 mx-auto lg:mt-24">
            <div class="w-10/12 lg:w-5/12 pt-4 space-y-3">
                <h2 class="md:my-6 font-semibold text-base md:text-xl text-slate-700">{{ item.bTitle }}</h2>
                <p class="text-blue-700 text-xs">{{ item.bLabel }}</p>
                <p class="text-xs text-slate-600 lg:w-8/12"> {{ item.bLead }}</p>
            </div>
            <div
                class="flex flex-col md:flex-row space-y-4 md:space-y-0 lg:space-x-8 md:w-11/12 justify-center items-center">

                <!-- cards -->
                <div class="hover:scale-105 bg-white rounded-md flex flex-col space-y-4 lg:space-y-6 mt-4 lg:mt-0 w-11/12 lg:w-5/12  shadow-lg relative z-0"
                    v-for="card in item.cards" :key="card.index">
                    <!-- cards principal page -->
                    <div v-if="index === 0">
                        <div class="h-52 space-y-4 w-48">
                            <div class="flex items-center justify-start space-x-3 pt-4 pl-4">
                                <img src="../assets/icons/notebook_icon.png" width="32" height="32" alt="Notebook"
                                    v-if="card.icon === 'notebook'">
                                <img src="../assets/icons/console_icon.png" width="32" height="32" alt="Video Game"
                                    v-if="card.icon === 'console'">
                                <img src="../assets/icons/smartphone_icon.png" width="32" height="32" alt="Smartphone"
                                    v-if="card.icon === 'smartphone'">
                                <p class="text-xs md:text-base text-center text-black font-bold">{{ card.title }}</p>
                            </div>
                            <ul class="text-xs mx-auto my-auto text-slate-600 list-disc w-9/12">
                                <li v-for="lista in card.list" :key="lista.item">{{ lista.item }}</li>
                            </ul>

                            <div class="absolute bottom-4 left-5">
                                <a class="rounded-md px-2 py-1 text-black font-normal mx-auto flex justify-around border border-sky-400 w-24 lg:w-28 items-center text-xs hover:scale-105"
                                    :href="card.link" title="Link para mais informações">Saiba Mais</a>
                            </div>
                        </div>
                    </div>

                    <!-- cards notebook page -->
                    <div v-else>
                        <div class="h-48 space-y-4 w-48">
                            <div class="flex items-center justify-start space-x-3 pt-4 pl-4">
                                <img src="../assets/icons/notebook_icon.png" width="32" height="32" alt="Notebook">
                                <p class="text-xs md:text-base text-center text-black font-bold">{{ card.title }}</p>
                            </div>
                            <ul class="text-xs mx-auto my-auto text-slate-600 list-disc w-9/12">
                                <li v-for="lista in card.list" :key="lista.item">{{ lista.item }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'Principal',
    props: ['dc']
}
</script>
