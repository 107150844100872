<template>
    <div class="flex flex-col justify-center mx-auto w-11/12">
        <div>
            <h2 class="text-base lg:text-6xl font-bold text-shadow">
                {{ dc.content.footer.title }}
            </h2>
        </div>
        <div class="flex flex-col lg:mt-12">

            <div class="flex flex-col lg:flex-row justify-center items-center w-full">

                <div class="flex flex-col lg:flex-row w-11/12 lg:w-8/12">
                    <div class="space-y-4 lg:space-y-4 my-12 lg:my-0 lg:mt-16">
                        <p class="text-xs lg:text-base text-blue-600">
                            Entre em contato
                        </p>
                        <p class="text-xs lg:text-base text-slate-600">
                            Teremos o maior prazer em lhe atender.

                        </p>
                        <div class="flex justify-start items-center">
                            <a href="https://wa.me/5546991105106" target="_blank"
                                class="shadow-lg rounded-md bg-blue-600 p-2 text-white font-bold flex justify-around w-24 items-center text-xs hover:scale-105"
                                title="Link do whatsapp Bili Informática">
                                <img src="../assets/icons/whatsapp_icon.png" width="18" height="18" alt="whatsapp"/>
                                Whatsapp
                            </a>

                        </div>
                    </div>
                </div>
                <div class="flex justify-center items-center w-6/12">
                    <div class="text-center flex justify-center items-center">
                        <iframe class="lg:w-[800px] lg:h-[400px]"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19239.46811915591!2d-52.740095119536946!3d-25.817858507941526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f01f6bd9133857%3A0x59f321578ce9af75!2sBili%20Inform%C3%A1tica!5e0!3m2!1spt-PT!2sbr!4v1685812483940!5m2!1spt-PT!2sbr"
                            style="border:0;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade" title="Nossa localização no google maps"></iframe>
                    </div>
                </div>
            </div>
            <div id="contact" class="mt-4 lg:ml-12">
                <div>
                    <h3 class="md:my-6 font-semibold text-base md:text-xl text-slate-700">Nossos Endereços</h3>
                </div>
                <div class="flex flex-row items-center space-x-4 my-4">
                    <a v-for="social in dc.content.social" :key="social.index" :href="social.link" :target="social.target"
                        :title="social.title">
                        <img class="hover:scale-105" src="../assets/icons/facebook_icon.png" :alt="social.alt" v-if="social.image == 'facebook'" width="32" height="32"/>
                        <img class="hover:scale-105" src="../assets/icons/instagram_icon.png" :alt="social.alt" v-if="social.image == 'instagram'" width="32" height="32"/>
                        <img class="hover:scale-105" src="../assets/icons/youtube_icon.png" :alt="social.alt" v-if="social.image == 'youtube'" width="32" height="32"/>
                    </a>
                    <a v-for="contact in dc.content.contact" :key="contact.index" :href="contact.link" :target="contact.target"
                        :title="contact.title">
                        <img class="hover:scale-105" src="../assets/icons/email_icon.png" :alt="contact.alt" v-if="contact.image == 'gmail'" width="32" height="32"/>
                        <img class="hover:scale-105" src="../assets/icons/whatsapp_icon.png" :alt="contact.alt" v-if="contact.image == 'whatsapp'" width="32" height="32"/>
                    </a>
                </div>
            </div>
            <div class="md:w-6/12">
                <p class="text-slate-600 text-sm mt-4">
                    {{ dc.content.footer.rua }}
                    <br>
                    {{ dc.content.footer.bairro }} - {{ dc.content.footer.estado }}
                </p>
                <p class="text-slate-600 text-sm mt-4">
                    {{ dc.content.footer.horario }}
                </p>
            </div>
        </div>
        <div class="w-full flex justify-end items-center mt-6 mb-2">
            <div class="flex justify-between md:w-7/12 space-x-4">
                <span class="text-[10px] md:text-[12px] font-light"> {{ date }} {{ dc.content.footer.copy }}</span>
                <span class="text-[10px] md:text-[12px] font-light">
                    Power By
                    <a class="text-sky-600" href="https://tiagoneitzke.com.br/" target="_blank" title="Link para a página dos desenvolvedores">{{ dc.content.footer.dev_link_label }}</a>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'Footer',
    props: {
        date: {
            type: [String, Number],
            default: new Date().getFullYear()
        },
        dc: 'dc'
    },
}
</script>