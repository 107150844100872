<template>
  <NavBar :dc="dc"/>
  <Principal :dc="dc"/>
  <Footer :dc="dc"/>
</template>

<script>
import DataContent from './assets/content.json'
import NavBar from './components/NavBar.vue'
import Principal from './components/Principal.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Principal,
    Footer
  },
  data() {
    return {
      dc: DataContent,
    };
  }
}
</script>
