<template>
  <div class="overflow-y-hidden w-full bg-slate-200 fixed z-50">
    <div class="text-white ">
      <nav class="2xl:mx-auto sm:py-6 sm:px-7 py-5 px-4">
        <!-- For large and Medium-sized Screen -->
        <div class="flex justify-between items-center">

          <div class="flex space-x-12 items-center">
            <!-- Logo area -->
            <h1 class="text-2xl text-black font-semibold">{{ dc.content.navbar.title }}</h1>
            <div class="hidden sm:flex flex-row justify-start">
              <nav class="">
                <ul class="flex space-x-8">
                  <li v-for="link in dc.content.navbar.links" :key="link.title"><a
                      class="hover:underline font-medium text-black text-sm" :href="link.link">{{ link.title }}</a></li>
                </ul>
              </nav>
            </div>
          </div>

          <!-- Social medias -->
          <div class="hidden sm:flex justify-center items-center space-x-6">
            <div v-for="social in dc.content.social" :key="social.title">
              <a :href="social.link" :target="social.target" :title="social.title">
                <img src="../assets/icons/facebook_icon.png" :alt="social.alt" v-if="social.image == 'facebook'"
                  width="32" height="32" />
                <img src="../assets/icons/instagram_icon.png" :alt="social.alt" v-if="social.image == 'instagram'"
                  width="32" height="32" />
                <img src="../assets/icons/youtube_icon.png" :alt="social.alt" v-if="social.image == 'youtube'" width="32"
                  height="32" />
              </a>
            </div>
          </div>

          <!-- Burger Icon -->
          <div id="bgIcon" @click="toggleMenu()"
            class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  flex justify-center items-center sm:hidden cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black"
              class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="black" class="w-6 h-6 hidden">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>

          </div>
        </div>

        <!-- Mobile and small-screen devices (toggle Menu) -->
        <div id="MobileNavigation" class="hidden sm:hidden mt-4 mx-auto">
          <div class="flex flex-row items-center justify-center space-x-6">
            <div v-for="social in dc.content.social" :key="social.title">
              <a :href="social.link" :target="social.target" :title="social.title">
                <img src="../assets/icons/facebook_icon.png" :alt="social.alt" v-if="social.image == 'facebook'"
                  width="32" height="32" />
                <img src="../assets/icons/instagram_icon.png" :alt="social.alt" v-if="social.image == 'instagram'"
                  width="32" height="32" />
                <img src="../assets/icons/youtube_icon.png" :alt="social.alt" v-if="social.image == 'youtube'" width="32"
                  height="32" />
              </a>
            </div>
          </div>
          <div class="flex flex-col gap-4 mt-4 w-80 mx-auto ">
            <nav class="">
              <ul class="flex justify-between w-48">
                <li><a class="hover:underline font-medium text-black text-sm" href="#home">Home</a></li>
                <li><a class="hover:underline font-medium text-black text-sm" href="#services">Serviços</a></li>
                <li><a class="hover:underline font-medium text-black text-sm" href="#contact">Contato</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </nav>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'NavBar',
  data() {
    return {}
  },
  methods: {
    toggleMenu() {
      var icon = document.getElementById('bgIcon');
      const childEle = icon.children;
      childEle[0].classList.toggle('hidden');
      childEle[1].classList.toggle('hidden');

      document.getElementById('MobileNavigation').classList.toggle('hidden');

    }
  },
  props: ['dc'],
}
</script>